const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;


// ((((((((((((((((((((( TODO )))))))))))))))))))))

// Blender
// - align materials/combos between optimized and non-optimized

// once lighting is finalized...
// set light values in a-scene.json
// background color blend with edge of ground



// ((((((((((((((((((((( NICE TO HAVE )))))))))))))))))))))

// bike materials
// - fix yellow reflector light
// - remove some of the strips of metal up under the bike as they have aliasing issues
// - make lights emit (having a sorta fog look around them would be sweet)

// enable changing material color of bike (start with just changing from grey to red)

// Improve loading screen and transition into scene
// - render or 3d image of bike on loading screen would be cool
// - incorprate the ground texture into the loading screen to make a more seamless transition
// - camera animation when scene is presented. Could be simple like a dolly in to the bike


// points of interest on bike

// 2D UI at top middle of screen with Energica's logo and Eva bike name? Could slide up from loading screen

// optimize model
// - some mesh could probably use phong or lambert material (already some using basic)
// - try gltfpack
// - room for more decimation if needed
// - bake normal maps where geometry is very detailed

// ((((((((((((((((((((()))))))))))))))))))))

AFRAME.registerComponent( 'environment_lighting', {

	schema: {
		src: { type: 'string' }
	},

	init() {

		this.asset_helper = this.el.sceneEl.systems[ 'asset_helper' ];
		this.motorcycle_uvs_el = document.getElementById( 'motorcycle-uvs' ); // carbon fiber is the uv mesh
		this.motorcycle_normals_el = document.getElementById( 'motorcycle-normals' ); // everything without textures is the normals mesh

		// (((((((((((((( for testing ))))))))))))))
		this.preset_0 = {
			toneMapping: THREE.ACESFilmicToneMapping,
			toneMappingExposure: 2.5,
			envMapIntensity: 0.1,
			directionalIntensity: 1.5,
			hemisphereIntensity: 0,
			shadowRadius: 2
		};
		this.preset_1 = {
			toneMapping: THREE.ACESFilmicToneMapping,
			toneMappingExposure: 2.5,
			envMapIntensity: 0.1,
			directionalIntensity: 1.5,
			hemisphereIntensity: 0.5,
			shadowRadius: 2
		};
		this.preset_2 = {
			toneMapping: THREE.ACESFilmicToneMapping,
			toneMappingExposure: 2.5,
			envMapIntensity: 0.1,
			directionalIntensity: 1.5,
			hemisphereIntensity: 1,
			shadowRadius: 2
		};
		this.preset_3 = {
			toneMapping: THREE.ACESFilmicToneMapping,
			toneMappingExposure: 2.5,
			envMapIntensity: 0.1,
			directionalIntensity: 1.5,
			hemisphereIntensity: 1.5,
			shadowRadius: 2
		};
		this.preset_4 = {
			toneMapping: THREE.ACESFilmicToneMapping,
			toneMappingExposure: 2.5,
			envMapIntensity: 0.15,
			directionalIntensity: 1.5,
			hemisphereIntensity: 1.5,
			shadowRadius: 2
		};
		this.presets = {
			active: '2'
		};
		// (((((((((((((( for testing ))))))))))))))

		this.setActivePreset();

		if ( window.GuiActive )
			this.setupGui();

		this.adjustRenderer();

		this.loadEnvironmentTexture();

		this.updateLights();

		this.motorcycle_uvs_el.addEventListener( 'object3dset', this.updateAllMaterials.bind( this ) );
		this.motorcycle_normals_el.addEventListener( 'object3dset', this.updateAllMaterials.bind( this ) );

	},

	setActivePreset: function () {

		this.guiObj = this[ `preset_${this.presets.active}` ];

	},

	adjustRenderer: function () {

		let renderer = this.el.sceneEl.renderer;

		renderer.toneMapping = Number( this.guiObj.toneMapping );
		renderer.toneMappingExposure = this.guiObj.toneMappingExposure;

	},

	loadEnvironmentTexture: function () {

		this.asset_helper.PMREMGenerator.compileEquirectangularShader();

		this.asset_helper.RGBELoader.load( '/textures/studio_1k.hdr', ( texture ) => {

			// generate envMap used for lighting
			const envMap = this.asset_helper.PMREMGenerator.fromEquirectangular( texture ).texture;
			this.asset_helper.PMREMGenerator.dispose();

			// apply PMREM texture as envMap on all motorcycle materials
			this.el.sceneEl.object3D.environment = envMap;

			this.updateAllMaterials();

			// signals to loading sequence that envMap is ready
			this.el.emit( 'EnvMapApplied' );

		} );


	},


	updateAllMaterials: function () {

		this.motorcycle_uvs_el.object3D.traverse( ( node ) => {

			if ( node.isMesh && node.material instanceof THREE.MeshStandardMaterial ) {

				node.material.envMapIntensity = this.guiObj.envMapIntensity;
				node.material.needsUpdate = true;

			}

		} );

		this.motorcycle_normals_el.object3D.traverse( ( node ) => {

			if ( node.isMesh && node.material instanceof THREE.MeshStandardMaterial ) {

				node.material.envMapIntensity = this.guiObj.envMapIntensity;
				node.material.needsUpdate = true;

			}

		} );

	},

	updateLights: function () {

		let directionalLight = document.getElementById( 'directional-light' );
		AFRAME.utils.entity.setComponentProperty( directionalLight, 'light.intensity', this.guiObj.directionalIntensity );
		AFRAME.utils.entity.setComponentProperty( directionalLight, 'light.shadowRadius', this.guiObj.shadowRadius );

		let hemisphereLight = document.getElementById( 'hemisphere-light' );
		AFRAME.utils.entity.setComponentProperty( hemisphereLight, 'light.intensity', this.guiObj.hemisphereIntensity );

		setTimeout( () => {

			this.updateShadowMap();

		}, 1000 );

	},

	updateShadowMap: function () {

		this.el.sceneEl.renderer.shadowMap.autoUpdate = false;
		this.el.sceneEl.renderer.shadowMap.needsUpdate = true;

	},

	setupGui: function () {

		let Gui = window.Gui;

		// __ presets __
		let presets = Gui.addFolder( 'presets' );
		presets.add( this.presets, 'active', {
			darkest: '0',
			darker: '1',
			default: '2',
			lighter: '3',
			lightest: '4',
		} ).onFinishChange( () => {

			this.setActivePreset();
			this.adjustRenderer();
			this.updateLights();
			this.updateAllMaterials();

		} );

		// // __ renderer __
		// let renderer = Gui.addFolder( 'renderer' );
		// renderer.add( this.guiObj, 'toneMappingExposure' ).min( 0 ).max( 10 ).step( 0.1 ).onFinishChange( this.adjustRenderer.bind( this ) );
		// renderer.add( this.guiObj, 'toneMapping', {
		// 	None: THREE.NoToneMapping,
		// 	Linear: THREE.LinearToneMapping,
		// 	Reinhard: THREE.ReinhardToneMapping,
		// 	Cineon: THREE.CineonToneMapping,
		// 	ACESFilmic: THREE.ACESFilmicToneMapping
		// } ).onFinishChange( () => {

		// 	this.adjustRenderer();
		// 	this.updateAllMaterials();

		// } );

		// // __ lighting __
		// let lighting = Gui.addFolder( 'lighting' );
		// lighting.add( this.guiObj, 'envMapIntensity' ).min( 0 ).max( 2 ).step( 0.01 ).onFinishChange( this.updateAllMaterials.bind( this ) );
		// lighting.add( this.guiObj, 'directionalIntensity' ).min( 0 ).max( 10 ).step( 0.1 ).onFinishChange( this.updateLights.bind( this ) );
		// lighting.add( this.guiObj, 'shadowRadius' ).min( 0 ).max( 10 ).step( 0.1 ).onFinishChange( this.updateLights.bind( this ) );
		// lighting.add( this.guiObj, 'hemisphereIntensity' ).min( 0 ).max( 10 ).step( 0.1 ).onFinishChange( this.updateLights.bind( this ) );

	}

} );
