import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { UnsignedByteType } from 'three';

const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

// helper system for loading models and textures
AFRAME.registerSystem( 'asset_helper', {

	init() {

		this.setupAssetLoaders();

	},

	// setup asset loaders for other components to use
	setupAssetLoaders: function () {

		// loading manager for any files we load outside of aframe's asset management system (a-assets)
		this.loadingManager = new THREE.LoadingManager();
		this.setupLoadingHandlers();

		// generic fileLoader
		this.fileLoader = new THREE.FileLoader( this.loadingManager );

		// gltf loader
		this.GLTFLoader = new THREE.GLTFLoader( this.loadingManager );

		// draco loader
		var dracoLoader = new THREE.DRACOLoader();
		dracoLoader.setDecoderPath( '/draco/' );
		this.GLTFLoader.setDRACOLoader( dracoLoader );

		// ktx2 loader
		var ktx2Loader = new KTX2Loader();
		ktx2Loader.detectSupport( this.el.sceneEl.renderer );
		this.GLTFLoader.setKTX2Loader( ktx2Loader );

		// image loader
		this.imageLoader = new THREE.ImageLoader( this.loadingManager );

		// texture loader
		this.textureLoader = new THREE.TextureLoader( this.loadingManager );

		// basis texture loader
		this.basisLoader = new BasisTextureLoader( this.loadingManager );
		this.basisLoader.setTranscoderPath( '/basis/' );
		this.basisLoader.detectSupport( this.el.sceneEl.renderer );

		// RGBE loader (for hdr envMap texture)
		this.RGBELoader = new RGBELoader( this.loadingManager ).setDataType( UnsignedByteType );

		// PMREM Generator for envMap to create accurate lighting effect or for equirectangular envMap to work at all
		this.PMREMGenerator = new THREE.PMREMGenerator( this.el.sceneEl.renderer );

	},

	setupLoadingHandlers: function () {

		// // called when an asset starts loading
		// this.loadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {

		// 	console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );

		// };

		// // called when each individual asset has finished loading
		// this.loadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {

		// 	console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );

		// };

		// called when all assets have been loaded
		this.loadingManager.onLoad = function ( ) {

			document.dispatchEvent( new CustomEvent( 'AllAssetsAreLoaded' ) );

		};

	}

} );
