const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent( 'mobile_fov', {

	schema: {},

	init() {

		if ( this.el.sceneEl.isMobile )
			this.widenFOV();

	},

	widenFOV: function () {

		AFRAME.utils.entity.setComponentProperty( this.el, 'camera.fov', 90 );

	}

} );
