import * as dat from 'dat.gui';

const AFRAME = window.AFRAME;

AFRAME.registerSystem( 'gui_debugger', {

	init: function () {

		if (
			window.location.hostname === "localhost" ||
			window.location.hostname.includes( "ngrok" ) ||
			window.location.hostname.includes( "netlify" )
		) {

			if ( window.location.href.includes( 'debug' ) )
				window.GuiActive = true;

		}


		if ( window.GuiActive && ! this.el.sceneEl.isMobile ) {

			window.Gui = new dat.GUI( { width: 420 } );

		}

	}
} );
