const AFRAME = window.AFRAME;
const THREE = AFRAME.THREE;

AFRAME.registerComponent( 'gltf_material', {
	schema: {
		action: { type: 'string', oneOf: [ 'swap', 'edit' ], default: 'swap' },
		transparent: { type: 'boolean', default: false },
		opacity: { type: 'float', default: 1.0 },
		alphaMap: { type: 'string' },
		shader: { type: 'string', oneOf: [ 'basic', 'lambert', 'phong', 'standard' ], default: 'basic' },
		color: { type: 'string', default: 'white' },
		materialTargetName: { type: 'string' },
	},

	init() {

		if ( this.el.getObject3D( 'mesh' ) ) {

			if ( this.data.action === 'swap' )
				this.swapMaterial();
			else
				this.editMaterial();

		} else {

			this.el.addEventListener( 'object3dset', () => {

				if ( this.data.action === 'swap' )
					this.swapMaterial();
				else
					this.editMaterial();

			} );

		}

	},


	editMaterial: function () {

		let asset_helper = this.el.sceneEl.systems.asset_helper;

		if ( this.data.alphaMap ) {

			asset_helper.textureLoader.load( this.data.alphaMap, ( texture ) => {

				this.el.getObject3D( 'mesh' ).traverse( ( node ) => {

					if ( node.isMesh ) {

						if ( this.data.alphaMap )
							node.material.alphaMap = texture;

						node.material.needsUpdate = true;

					}

				} );

			} );

		}

		if ( this.data.transparent ) {

			this.el.getObject3D( 'mesh' ).traverse( ( node ) => {

				if ( node.isMesh ) {

					node.material.transparent = this.data.transparent;
					node.material.opacity = this.data.opacity;

				}

			} );

		}

	},


	swapMaterial: function () {

		let newMaterial, oldMaterial;
		let materialTargetName = this.data.materialTargetName;

		// setup newMaterial
		switch ( this.data.shader ) {

			case 'basic':
				newMaterial = new THREE.MeshBasicMaterial( { color: this.data.color } );
				break;

			case 'lambert':
				newMaterial = new THREE.MeshLambertMaterial( { color: this.data.color } );
				break;

			case 'phong':
				newMaterial = new THREE.MeshPhongMaterial( { color: this.data.color } );
				break;

			case 'standard':
				newMaterial = new THREE.MeshStandardMaterial( { color: this.data.color } );
				break;

			default:
				break;

		}

		// apply new material and dispose of old material
		this.el.getObject3D( 'mesh' ).traverse( ( node ) => {

			if ( ! node.isMesh || ! node.material ) return;
			if ( materialTargetName && node.material.name != materialTargetName ) return;

			oldMaterial = node.material;

			node.material = newMaterial;

			if ( materialTargetName )
				node.material.color = oldMaterial.color;

			oldMaterial.dispose();

		} );

	},

} );
